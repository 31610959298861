<template>
  <div class="component-not-found">
    <h3>{{ $t('componentNotFound') }}</h3>
  </div>
</template>

<script>
export default {
  name: 'ComponentNotFound',
};
</script>

<style scoped lang="scss">
.component-not-found {
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 40%);
  background-color: #fff;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 32px 16px;
}
</style>
