<script>
import { h } from 'vue';
import _global from '#root/src/components/_global';
import {
  findComponentByPlace,
  getComponentParams,
  regexpOnlyBrTemplate,
  regexpTemplate,
} from '@/utils/parsePage.utils';
import BaseContainer from '@/components/BaseContainer.vue';
import RenderHtml from '@/components/RenderHtml.vue';
import ComponentNotFound from '@/admin-components/ComponentNotFound.vue';

export default {
  props: {
    /**
     * @param {string} content
     * @param {Array} components
     */
    page: {
      type: Object,
      required: true,
    },
  },
  render(props) {
    const globalComponent = _global;
    const content = props.page.content || '';
    const components = props.page.components || [];
    let splitContent =
      content.replace(/\r\n/gi, '').replace('&nbsp;', '').split(regexpTemplate) || [];

    splitContent = splitContent.filter((str) => str !== '');
    splitContent = splitContent.filter((str) => !regexpOnlyBrTemplate.test(str));
    splitContent = splitContent.map((str) => {
      if (regexpTemplate.test(str)) {
        const component = findComponentByPlace(str, components);

        if (component && globalComponent[component.name]) {
          const params = getComponentParams(component);

          return h(globalComponent[component.name], {
            key: component.uuid,
            params,
          });
        }

        return h(ComponentNotFound);
      }
      const isFlomni = /id="(flomni|flomni-inline)"/.test(str);
      if (isFlomni) {
        return null;
      }

      return h(BaseContainer, {}, () => h(RenderHtml, { html: str }));
    });

    return h('div', this.$attrs, splitContent);
  },
};
</script>
