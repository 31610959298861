<template>
  <div class="base-render-page" :class="pageClass">
    <!--   TODO:  Нашел использование custom-meta-tags ровно в одно месте. Проверить когда понадобится -->
    <keep-alive>
      <render-component :page="page" :custom-meta-tags="[]" class="base-render-page" />
    </keep-alive>
  </div>
</template>

<script lang="ts" setup>
import { computed, inject, onBeforeUnmount, onMounted, provide, ref, watch } from 'vue';
import { VueCookies } from 'vue-cookies';
import RenderComponent from '@/components/RenderComponent.vue';
import IPage from '@/types/dto/page';
import { sendParams } from '@/utils/reach-goal';
import { usePageContext } from '@/composables/use-page-context';
import useFlomni from '@/composables/use-flomni';
import { createScriptElement, getHeadElement } from '@/utils/dom.utils';
import { handleTildaYm, removeTildaYm } from '@/utils/handle-tilda-ym';

const props = withDefaults(defineProps<{ page: IPage; metaData: unknown; isTilda?: boolean }>(), {
  isTilda: false,
});

const pageContext = usePageContext();

const { addListenersToLinks, removeListenersFromLinks } = useFlomni();

provide('pageContent', props.page.content);

const loadedScripts = ref(0);
const $cookies = inject<VueCookies>('$cookies');

// При навигации по сайту, head захламляется скриптами которые
// запихиваем на страницах тильды, перед тем как покидаем страницу
// желательно подчищать за собой

const pageClass = computed(() => {
  const customClass =
    `page-${pageContext?.urlPathname?.replace(/^\/[\w\d]+\//, '')?.replace('/', '')}` || '';

  return {
    'base-render-page--tilda': props.isTilda,
    [customClass]: true,
  };
});

const scriptIds = ref<string[]>([]);

const appendScriptToHead = (src: string, idx: number) => {
  const head = getHeadElement();

  const script = createScriptElement();
  const id = `cdek_backend_tilda_script_${idx}`;

  script.src = src;
  script.defer = true;
  script.id = id;

  scriptIds.value.push(id);

  head.appendChild(script);
  // С бекенда нам приходит набор скриптов this.scriptsJS и верстка страницы тильды.
  // Верстка содержит в себе блоки <script /> в которых происходит вызов функций определяемых в скриптах из this.scriptsJS.
  // В файле nuxt.config.js я замокал функцию t_onReady чтобы при первичной отрисовке страница не ломалась.
  script.onload = () => {
    loadedScripts.value += 1;

    // После того как все скрипты загружены можем по новой попытаться сделать тильду интерактивной
    // выполнив то что приходило к нам в верстке
    if (loadedScripts.value === props.page.js.length) {
      const tildaContainer = document.getElementById('allrecords');
      if (!tildaContainer) {
        return;
      }

      const scripts = [...tildaContainer.getElementsByTagName('script')];

      scripts.forEach((el, innerIdx) => {
        const newScript = document.createElement('script');
        const innerId = `cdek_tilda_script_${innerIdx}`;
        scriptIds.value.push(innerId);

        newScript.type = 'text/javascript';
        newScript.innerHTML = el.innerHTML;
        newScript.id = innerId;

        head.appendChild(newScript);
      });

      handleTildaYm();
    }
  };
};

const handleTildaPage = () => {
  if (props.page.isTilda) {
    const pageLayout = document.getElementById('work-area');
    if (pageLayout) pageLayout.style.zIndex = '1';
  }

  // Изначально эти скрипты запихивались просто в head. Была проблема с тем что непонятно когда что выполняется
  // Судя по всему скрипты отрабатывали до получения верстки, и страница не становилась интерактивной
  // Поэтому вынес добавление скриптов в хеад только после маунта. Чтобы ожидать одинакового поведения для сср и спа
  props.page?.js?.forEach((src, idx) => {
    appendScriptToHead(src, idx);
  });
};

const removeTildaPage = () => {
  removeTildaYm();

  scriptIds.value.forEach((id) => {
    const scriptToRemove = document.getElementById(id);

    if (scriptToRemove) {
      scriptToRemove.remove();
    }
  });
  loadedScripts.value = 0;
  scriptIds.value = [];
  const pageLayout = document.getElementById('work-area');
  if (pageLayout) pageLayout.style.zIndex = '';
};
watch(
  () => props.page,
  () => {
    removeTildaPage();
    handleTildaPage();
  },
);

onMounted(() => {
  handleTildaPage();

  addListenersToLinks();

  if (!$cookies) {
    return;
  }

  if ($cookies.get('ab_test_map')) {
    sendParams({
      ab_test_map: $cookies.get('ab_test_map'),
    });
  }
});
onBeforeUnmount(() => {
  removeListenersFromLinks();

  removeTildaPage();
});
</script>

<script lang="ts">
export { default as Layout } from '#root/src/layouts/DefaultLayout.vue';
</script>
